import { ReactNode } from 'react';
import { Footer } from './Footer';
import { Header } from './Header';
import styles from './Layout.module.scss';
import { MetaType, SettingsType } from '@/types';
import { Sidebar } from './Sidebar';
import { PageBackdrop } from '@/components/PageBackdrop';
import Meta from '@/components/Meta';

type LayoutProps = {
  data: SettingsType;
  /**
   * Pass an empty meta data if not required (constant EMPTY_META)
   */
  meta: MetaType;
  children: ReactNode;
};

export function Layout({
  data,
  meta = { title: 'Остров мечты' },
  children,
}: LayoutProps) {
  const { menuFooter, mode, socials, disclaimer, copyright, address, phone } =
    data;

  return (
    <div className={styles.root}>
      <div className={styles.adaptive}>
        {data && <Header menu={data.menu} links={data.links} />}
        {children}
        {data && <Sidebar menu={data.menu} />}
        <PageBackdrop />
      </div>
      {data && (
        <Footer
          menu={menuFooter}
          timeTable={mode}
          socials={socials}
          disclaimer={disclaimer}
          copyright={copyright}
          address={address}
          phone={phone}
        />
      )}
      <Meta
        title={meta.title}
        description={meta.description}
        image={meta.picture}
        canonical={meta.canonical}
        robots={meta.robots}
        keywords={meta.keywords}
        h1={meta.h1}
        text={meta.text}
      />
    </div>
  );
}
